/**
 * @flow
 */

type ToolTipsType = {
  employeeSubmission: string,
  eligibility: string,
  pending: string,
  reliefRequired: string,
  supernumerary: string,
  quotaWeekly: string,
  quotaSummary: string,
  quotaDisclaimer: string,
  vacationHoursLikelihoodSupplementalVacation: string,
  vacationHoursLikelihoodNoSupplementalVacation: string,
  processedDisclaimer: string,
  approvedPercent: string,
  eligibleHomeAssignments: string,
  resolutionMethod: string,
  quotaDaily: string,
  viewingAdvancedQuota: string,
  editingAdvancedQuota: string,
  editingSingleDailyQuota: string,
};

export const ToolTips: ToolTipsType = {
  employeeSubmission: "The number of employee submissions ready for processing.",
  eligibility:
    "The date range in which you hold a position within a vacation group for the upcoming year. " +
    "You can only select vacation that falls within your eligibility range.",
  pending: "Pending amount reflects your top ranked vacation request for all unprocessed rounds.",
  reliefRequired: "Indicate to Scheduling Staff if relief is required.",
  supernumerary: "Indicates if the request includes supernumerary shifts.",
  quotaWeekly: "Allowed vacation per calendar week.",
  quotaSummary: "Allowed vacation per calendar day with combined day and week quota likelihood.",
  quotaDisclaimer: "Disclaimer: Quota does not include pending requests for unprocessed participants.",
  vacationHoursLikelihoodSupplementalVacation: window.pgettext(
    "A Tooltip help message informing a user how their vacation hours were calculated",
    "Based on both Estimated Entitlement bank and Supplemental Vacation bank combined."
  ),
  vacationHoursLikelihoodNoSupplementalVacation: window.pgettext(
    "A Tooltip help message informing a user how their vacation hours were calculated",
    "Based on Estimated Entitlement bank."
  ),
  processedDisclaimer: "Processed consists of approved, denied, or skipped vacation requests.",
  approvedPercent: "Includes all approved vacation requests for this group.",
  eligibleHomeAssignments:
    "Employees may have multiple home assignments within a vacation group. " +
    "Only one participant will be created per employee.",
  resolutionMethod:
    `"Export Schedule Changes" is only an option for ${window.gettext("#[JARGON:departments]")} ` +
    "that are allowlisted for Schedule Sync.",
  quotaDaily: "Allowed vacation per calendar day. Displayed quota includes the selected request.",
  viewingAdvancedQuota:
    "Advanced quota specifies the amount of maximum available vacation for Day, Evening and Night time ranges.",
  editingAdvancedQuota:
    "Advanced quota allows you to specify the maximum available vacation for Day, Evening, and " +
    "Night time ranges, as well as a daily maximum.",
  editingSingleDailyQuota: "Single quota allows you to specify the maximum available vacation for a single day.",
};
